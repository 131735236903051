<template>
  <div>
    <div class="nurseOffice mt10" v-loading="loadingData">
      <div class="mt10 mb-3">
        <el-breadcrumb class="mt-3" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/hospital' }">{{
            $t("message.hospital")
          }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{
            $t("message.nurse_office")
          }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="nurseOffice_tabs">
        <el-card class="box-card mb-4 pl-3 pr-3 pt-2">
          <!-- <el-row :gutter="20">
            <el-col :span="24">Поиск Пациент</el-col>
          </el-row>-->
          <el-form ref="form" :model="form" class="aticler_m p-0 mt-4" size="small">
            <el-row :gutter="15">
              <el-col :sm="24" :md="6" :lg="6">
                <el-form-item :label="$t('message.number_card')">
                  <el-input suffix-icon="el-icon-search" v-model="number" :placeholder="$t('message.number_card')"
                    v-mask="'### ### ###'"></el-input>
                </el-form-item>
              </el-col>
              <el-col :sm="24" :md="6" :lg="6">
                <el-form-item :label="$t('message.patient')">
                  <patients @input="patientChanged" :patient_id="patient_id" v-model="patient_id"></patients>
                </el-form-item>
              </el-col>

              <el-col :xs="12" :sm="8" :md="6" :lg="6">
                <el-button class="butt_sacrch" style="width: 200px; margin-top: 20px" type="success" icon="el-icon-search"
                  size="medium" @click="search()" round>{{ $t("message.search_0") }}</el-button>
              </el-col>

              <div v-if="patientHistory.is_ended != 0 && patientHistory.is_ended" class="left_textLastHistory_off">
                {{ $t("message.hospitalization_complated") }}!
                <span><b>{{ $t("message.user") }}:</b>
                  {{
                    patientHistory.endedUser
                    ? patientHistory.endedUser.name
                    : ""
                  }}
                  {{
                    patientHistory.endedUser
                    ? patientHistory.endedUser.surname
                    : ""
                  }}
                  {{
                    patientHistory.endedUser
                    ? patientHistory.endedUser.patronymic
                    : ""
                  }}</span>
              </div>
            </el-row>
          </el-form>
        </el-card>
        <el-dialog class="khan_dialog" width="80%" :append-to-body="true" :visible.sync="dialogTableVisible">
        </el-dialog>
        <template v-if="this.number == this.patientHistory.number">
          <el-tabs type="border-card">
            <el-tab-pane :label="$t('message.drug')">
              <Medicine :number="number" :isended="num" />
            </el-tab-pane>
            <el-tab-pane :label="$t('message.indicator')">
              <Result />
            </el-tab-pane>
          </el-tabs>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Medicine from "./components/medicine";
import Result from "./components/result";
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import patients from "@/components/inventory-select/hospital-patient-select-search";
import drawer from "@/utils/mixins/drawer";
export default {
  mixins: [list, drawer],
  name: "nurseOffice",
  components: {
    Medicine,
    Result,
    patients,
  },
  data() {
    return {
      patientHistory: {},
      hospitalPatient: {},
      number: "",
      dialogTableVisible: false,
      patient_id: null,
      num: "",
      form: {},
      loadingData: false,
      selectedModel: {},
      drawer: {
        create: {
          name: "create",
          status: false,
          component: "componentDrawerCreate",
        },
      },
    };
  },
  computed: {
    getId() {
      return this.$route.params.id;
    },
    ...mapGetters({
      activeCode: "patients/activeCode",
      eventfilter: "hospitalPatients/filter",
      list: "nurceOffices/list",
      columns: "nurceOffices/columns",
      pagination: "nurceOffices/pagination",
      filter: "nurceOffices/filter",
      sort: "nurceOffices/sort",
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  async mounted() { },
  // beforeRouteLeave(to, from, next) {
  //     this.empty();
  //     console.log(`I'm called before leaving the route!`);
  //     console.log(`I have access to the component instance, here's proof!`);
  //     next();
  // },
  methods: {
    ...mapActions({
      updateList: "nurceOffices/index",
      updateSort: "nurceOffices/updateSort",
      updateFilter: "nurceOffices/updateFilter",
      updateColumn: "nurceOffices/updateColumn",
      updatePagination: "nurceOffices/updatePagination",
      editModel: "nurceOffices/showItem",
      showNumber: "nurceOffices/showNumber",
      showPatient: "nurceOffices/showPatient",
      countActiveHistory: "nurceOffices/countActiveHistory",
      empty: "nurceOffices/empty",
      delete: "nurceOffices/destroy",
      refreshData: "nurceOffices/refreshData",
    }),
    search() {
      this.loadingData = true;
      this.showNumber({
        number: this.number,
      })
        .then((res) => {
          this.loadingData = false;
          this.patientHistory = res.data.patientHistory;
          this.num = res.data.patientHistory.is_ended;
          this.patient_id = res.data.patientHistory.hospitalPatient
            ? res.data.patientHistory.hospitalPatient.id
            : null;
        })
        .catch((err) => {
          this.loadingData = false;
          this.$notify({
            title: this.$t("message.validation"),
            type: "error",
            offset: 130,
            dangerouslyUseHTMLString: true,
            message: err.message,
          });
        });
    },
    patientChanged(val) {
      if (val != "" && val != null) {
        this.countActiveHistory(val).then((res) => {
          if (res.data.count == 0) {
            this.patient_id = null;
            this.$notify({
              title: this.$t("message.validation"),
              type: "error",
              offset: 130,
              dangerouslyUseHTMLString: true,
              message: "Ежедневное показатель Пациента не найдено",
            });
          } else if (res.data.count == 1) {
            this.loadingData = true;
            this.showPatient({
              patient: val,
            })
              .then((res) => {
                this.loadingData = false;
                this.patientHistory = res.data.patientHistory;
                this.number = this.patientHistory.number;
                this.num = res.data.patientHistory.is_ended;
              })
              .catch((err) => {
                this.loadingData = false;
                this.$notify({
            title: "Ошибка",
            type: "error",
            offset: 130,
            message: err.error.message,
          });
              });
          } else if (res.data.count > 1) {
          }
        });
      }
    },
    fetchData() { },
    refresh() {
      this.refreshData()
        .then((res) => {
          this.filterForm = JSON.parse(JSON.stringify(this.filter));
        })
        .catch((err) => { });
    },
    destroy(model) {
      this.delete(model.id)
        .then((res) => {
          this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
          this.fetchData();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emptyModel() {
      this.empty();
    },
  },
};
</script>

<style lang="scss">
.patsent_namber {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  height: 32px;
  line-height: 29px;
  font-size: 14px;
  cursor: not-allowed;
  background: #efefefc9;
}

.left_textLastHistory_off {
  padding-top: 7px;
  text-align: right;
  flex: 1;
  padding-right: 10px;
  color: red;
  font-size: 16px;

  span {
    margin-left: 20px;
    color: #717171;
  }
}
</style>
